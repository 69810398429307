<template>
  <div class="flex flex-col p-16">
    <div class="w-full items-center max-w-2xl md:text-left">
      <div class="pb-5">
        <router-link to="/" class=""
          ><button
            type="button"
            class="transition duration-500 ease-in-out  motion-reduce:transition-none motion-reduce:transform-none  bg-littleDarkishBlue mt-2 flex  inline-flex  text-white font-semibold px-6 py-2 mr-2 rounded-lg hover:bg-littleDarkBlue hover:text-white"
          >
            <div class="pr-2">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                ></path>
              </svg>
            </div>
            Back
          </button></router-link
        >
      </div>
      <div class="mb-16">
        <div
          class="text-5xl font-bold break-normal max-w-xs text-md  text-white mt-2 mb-8"
        >
          Technologies
        </div>
        <div>
          <animated-bar
            v-bind:toolName="'Python'"
            v-bind:percentage="100"
          ></animated-bar>
          <animated-bar
            v-bind:toolName="'JavaScript'"
            v-bind:percentage="65"
          ></animated-bar>
          <animated-bar
            v-bind:toolName="'Rust'"
            v-bind:percentage="49"
          ></animated-bar>
          <animated-bar
            v-bind:toolName="'Redis'"
            v-bind:percentage="74"
          ></animated-bar>
          <animated-bar
            v-bind:toolName="'MongoDB'"
            v-bind:percentage="74"
          ></animated-bar>
          <animated-bar
            v-bind:toolName="'Django/FastAPI/Flask'"
            v-bind:percentage="85"
          ></animated-bar>
          <animated-bar
            v-bind:toolName="'Vue.js (this website)'"
            v-bind:percentage="85"
          ></animated-bar>
        </div>

        <div class="font-bold text-5xl text-gray-200 mt-24 mb-8">
          Skills
        </div>
        <div
          class="font-sm break-normal max-w-xs text-md md:text-xl text-white mt-2 mb-5"
        >
          Here is what I have experience with.
        </div>
      </div>
      <div class="text-white">
        <div class="pb-4">
          <h1 class="text-4xl font-bold">
            Automation & API Intergration
          </h1>
          <ul class="list-disc mt-3 p-4 text-white">
            <li>F5 APIs</li>
            <li>Ansible (Playbooks + Module development)</li>
            <li>Cisco Meraki APIs</li>
            <li>Infoblox NetMRI</li>
            <li>Cisco Meraki APIs</li>
            <li>Infoblox NetMRI</li>
          </ul>
        </div>
        <div class="pb-4">
          <h1 class="text-4xl font-bold ">
            Corporate Development Environment
          </h1>
          <ul class="list-disc mt-3 p-4 text-white">
            <li>
              Developing Internal Facing APIs and Web Applications for Teams and
              divisions within the compnay.
            </li>
            <li>Experience with interacting with SQL Databases</li>
            <li>
              Experience with interacting with NoSQL Databases
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
              >
                Preferred
              </span>
            </li>
            <li>
              Scripts and Application data collection across multiple sources
              for AI/ML use cases.
            </li>
            <li>Unit Testing</li>
            <li>DevOps (Deployment)</li>
            <li>Docker, Docker Compose, Kubernetes & OpenShift</li>
            <li>
              RHEL, Ubuntu and other Linux variants
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
              >
                Preferred
              </span>
            </li>
          </ul>
        </div>
        <div class="pb-4">
          <h1 class="text-4xl font-bold ">
            Languages, Tools, & Services
          </h1>
          <ul class="list-disc mt-3 p-4 text-white">
            <li>
              Python
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
              >
                Preferred
              </span>
            </li>
            <li>
              JavaScript (Vue/Vanilla), Rust (Experience with binding with
              Python!), Golang, Ruby
            </li>
            <li>
              AWS, Google Cloud
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
              >
                Preferred
              </span>
            </li>
            <li>
              CloudFlare Workers, Amazon Lambda, Google App Engine, Amazon
            </li>
            <li>
              CloudFlare DNS
            </li>
            <li>
              ElasticSearch
            </li>
            <li>
              MongoDB, Redis
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
              >
                Preferred
              </span>
            </li>
            <li>
              (Python) FastAPI, Celery, Falcon, Django, Flask
            </li>
            <li>
              Jenkins, Drone.io, Github Actions. (I'm a fast learner for any
              CI/CD workflow)
            </li>
          </ul>
        </div>
        <router-link to="/email" class=""
          ><button
            type="button"
            class="transition duration-500 ease-in-out  motion-reduce:transition-none motion-reduce:transform-none  mt-2 flex  inline-flex bg-redishPink text-darkBlueBlack font-semibold px-6 py-2 mr-2 rounded-lg hover:bg-littleDarkBlue hover:text-white"
          >
            <div class="pr-2">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                ></path>
              </svg>
            </div>
            Want to get in touch?
          </button></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import animatedBar from "../components/animatedBar.vue";
export default {
  components: { animatedBar },
};
</script>
