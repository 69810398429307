<template>
  <div class="flex flex-col h-screen">
    <main class="flex-grow">
      <div class="absolute p-16 inset-0 flex items-center">
        <div class="w-full text-left items-center max-w-2xl">
          <div class="pb-5">
            <router-link to="/" class=""
              ><button
                type="button"
                class="transition duration-500 ease-in-out  motion-reduce:transition-none motion-reduce:transform-none  bg-littleDarkishBlue mt-2 flex  inline-flex  text-white font-semibold px-6 py-2 mr-2 rounded-lg hover:bg-littleDarkBlue hover:text-white"
              >
                <div class="pr-2">
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    ></path>
                  </svg>
                </div>
                Back
              </button></router-link
            >
          </div>
          <div class="font-bold text-6xl text-gray-200 mt-2">
            Email
          </div>
          <div
            class="font-sm break-normal max-w-xs text-md md:text-xl text-gray-300 mt-2 mb-5"
          >
            My resume is available on request.
          </div>
          <button
            type="button"
            @click="copyEmailToClipboard"
            class="transition duration-500 ease-in-out  motion-reduce:transition-none motion-reduce:transform-none flex  inline-flex bg-redishPink text-darkBlueBlack mt-2 font-semibold px-6 py-2 mr-2 rounded-lg hover:bg-littleDarkBlue hover:text-white"
          >
            <div class="pr-2">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                ></path>
              </svg>
            </div>
            Copy to Clipboard
          </button>
          <button
            @click="openEmailClient"
            type="button"
            class="transition duration-500 ease-in-out  motion-reduce:transition-none motion-reduce:transform-none  flex  inline-flex bg-redishPink text-darkBlueBlack mt-2 font-semibold px-6 py-2 mr-2 rounded-lg hover:bg-littleDarkBlue hover:text-white"
          >
            <div class="pr-2">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                ></path>
              </svg>
            </div>
            Open Email Client
          </button>
          <button
            @click="displayEmail"
            type="button"
            class="transition duration-500 ease-in-out  motion-reduce:transition-none motion-reduce:transform-none  flex  inline-flex bg-redishPink text-darkBlueBlack mt-2 font-semibold px-6 py-2 mr-2 rounded-lg hover:bg-littleDarkBlue hover:text-white"
          >
            <div class="pr-2">
              <svg
                v-if="showEmail"
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                ></path>
              </svg>
              <svg
                v-if="!showEmail"
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                ></path>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                ></path>
              </svg>
            </div>
            <span v-if="showEmail">
              Hide
            </span>
            <span v-else>
              View
            </span>
          </button>
          <transition
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            enter-active-class="transform ease-out duration-300 transition"
            enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            leave-to-class="opacity-0"
            leave-active-class="transition ease-in duration-100"
            leave-class="opacity-100"
          >
            <div
              v-if="showEmail"
              class="mt-4 max-w-sm w-full bg-littleDarkBlue text-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
            >
              <div class="p-4">
                <div class="flex items-start">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: outline/check-circle -->
                    <svg
                      class="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      ></path>
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      ></path>
                    </svg>
                  </div>
                  <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p class="text-sm font-medium">
                      {{ emailText }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </main>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
      aria-live="assertive"
      class="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
    >
      <transition
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        enter-active-class="transform ease-out duration-300 transition"
        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        leave-to-class="opacity-0"
        leave-active-class="transition ease-in duration-100"
        leave-class="opacity-100"
      >
        <div
          v-if="isCopiedNotificationShow"
          class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <!-- Heroicon name: outline/check-circle -->
                <svg
                  class="h-6 w-6 text-green-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ notificationShowText }}
                </p>
                <p class="mt-1 text-sm text-gray-500">
                  {{ notificationShowMessage }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "viewEmail",
  data() {
    return {
      isCopiedNotificationShow: false,
      notificationShowText: "",
      notificationShowMessage: "",
      showEmail: false,
      emailText: "Blocked.",
    };
  },
  methods: {
    openEmailClient() {
      this.saEvent(`userOpenedEmailClientWithEmail`);

      var encodedBaseString = "bXVzdGFmYUBtczdtLm1l";
      open(`mailto:${atob(encodedBaseString)}`, "_blank");
      this.notificationShowText =
        "Opening my email with your default mail client.";
      this.notificationShowMessage =
        "If there is any issues, go ahead and just copy my email";
      this.isCopiedNotificationShow = true;
      setTimeout(() => (this.isCopiedNotificationShow = false), 10000);
    },
    copyEmailToClipboard() {
      this.saEvent(`userCopiedEmail`);
      var encodedBaseString = "bXVzdGFmYUBtczdtLm1l";
      let container = this.$refs.container;
      this.$copyText(atob(encodedBaseString), container);

      this.notificationShowText = "Copied email to clipboard.";
      this.notificationShowMessage = "Please no spam. <3";
      this.isCopiedNotificationShow = true;
      setTimeout(() => (this.isCopiedNotificationShow = false), 5000);
    },
    displayEmail() {
      this.saEvent(`userDisplayedEmail`);
      if (this.showEmail == false) {
        var encodedBaseString = "bXVzdGFmYUBtczdtLm1l";

        this.emailText = atob(encodedBaseString);
        this.showEmail = true;
      } else {
        this.emailText = "Blocked";
        this.showEmail = false;
      }
    },
  },
};
</script>
