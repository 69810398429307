<template>
  <div class="flex flex-col h-screen">
    <main class="flex-grow">
      <div class="absolute p-16 inset-0 flex items-center">
        <div class="w-full text-left items-center max-w-2xl">
          <div class="font-bold text-3xl text-gray-200 mt-2">
            My Projects
          </div>
          <div
            class="font-sm break-normal max-w-1/2 text-md md:text-xl text-gray-300 mt-2 mb-5"
          >
            View my active projects, and some documentation for them.
          </div>
          <transition
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            enter-active-class="transform ease-out duration-300 transition"
            enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            leave-to-class="opacity-0"
            leave-active-class="transition ease-in duration-100"
            leave-class="opacity-100"
          >
            <div
              v-if="isLoading"
              class="mt-4 max-w-sm w-full bg-darkBlueBlack text-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
            >
              <div class="p-4">
                <div class="flex items-start">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: outline/check-circle -->
                  </div>
                  <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p class="text-sm font-bold">
                      Loading
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            enter-active-class="transform ease-out duration-300 transition"
            enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            leave-to-class="opacity-0"
            leave-active-class="transition ease-in duration-100"
            leave-class="opacity-100"
          >
            <div class="flex w-full">
              <div
                class="mt-4 max-w-sm ml-2 w-full bg-littleDarkishBlue text-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
              >
                <div class="p-4">
                  <div class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: outline/check-circle -->
                      <svg
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                      <a href="https://github.com/ms7m/notify-py">
                        <p class="text-md font-bold">
                          notify-py
                        </p>
                        <p class="text-sm font-normal">
                          An easy to use cross platform desktop notification
                          library for python.
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mt-4 max-w-sm ml-2 w-full bg-littleDarkishBlue text-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
              >
                <div class="p-4">
                  <div class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: outline/check-circle -->
                      <svg
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm3.293 1.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L7.586 10 5.293 7.707a1 1 0 010-1.414zM11 12a1 1 0 100 2h3a1 1 0 100-2h-3z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                      <a href="https://dispike.ms7m.me">
                        <p class="text-md font-bold">
                          dispike
                        </p>
                        <p class="text-sm font-normal">
                          An early experimental framework for creating Discord
                          bots using the new Slash commands. Powered by FastAPI.
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "viewProjects",
};
</script>
