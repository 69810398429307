<template>
  <div class="relative pt-1">
    <div class="flex mb-5 items-center justify-between">
      <div>
        <span
          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-littleDarkBlue"
        >
          {{ toolName }}
        </span>
      </div>
      <div class="text-right">
        <span class="text-xs font-semibold inline-block text-white">
          <span
            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-littleDarkBlue"
          >
            {{ returnStringRepresentation(percentage) }}
          </span>
        </span>
      </div>
    </div>
    <div class="overflow-hidden h-3 mb-4 text-xs flex rounded bg-pink-200">
      <div
        v-bind:style="`width: ${percentage}%`"
        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-darkishRedish"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: Number,
    toolName: String,
  },
  methods: {
    returnStringRepresentation: function(number) {
      // switch case number range
      switch (true) {
        case number < 25:
          return "Beginner";
        case number < 50:
          return "Intermediate";
        case number < 75:
          return "Advanced";
        case number <= 100:
          return "Fluent";
        default:
          // eslint-disable-next-line no-useless-escape
          return "¯\_(ツ)_/¯ - But I can learn quickly!";
      }
    },
  },
};
</script>

<style></style>
